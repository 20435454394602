<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :filters="filters"
    :prefetch="prefetch"
    :details-component="SkinItemDetails"
  />
</template>

<script>
import {
  VxTable,
  VxCellBadges,
  VxCellImage,
  VxCellLink,
  VxCellDetailsToggler,
  VxCellMoney,
  VxCellRarityBadge
} from '@/components/table'
import { boolStatuses, categories, passDataToResource, skins } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import { buildRoute, path } from '@/router'
import { computed } from '@vue/composition-api'
import SkinItemDetails from '@/views/skins/SkinItemDetails'
import useBaseInfo from '@/views/skins/useBaseInfo'

export default {
  name: 'SkinItems',
  components: {
    VxTable,
    VxCellImage,
    SkinItemDetails
  },
  setup () {
    const {
      rarities,
      exteriors,
      getBaseInfo,
      exteriorFormatter
    } = useBaseInfo()

    const resource = passDataToResource(skins.getAll, {
      requestParams: {
        params: { include: 'marketSkins,marketSkins.market,file,category,item' }
      }
    })

    const prefetch = async () => {
      await getBaseInfo()
    }

    const columns = [
      {
        key: 'details',
        label: '',
        component: VxCellDetailsToggler,
        tdClass: ['p-0', 'width-50']
      },
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'file',
        label: 'Image',
        component: VxCellImage
      },
      {
        key: 'category',
        component: VxCellLink,
        tdAttr: ({ id, name }) => {
          return {
            label: name,
            subLabel: `#${id}`,
            href: buildRoute(path.categories, { query: { id } })
          }
        }
      },
      {
        key: 'category.variation',
        label: 'Variation'
      },
      {
        key: 'exterior',
        formatter: exteriorFormatter
      },
      {
        key: 'category.rarity',
        label: 'Rarity',
        component: VxCellRarityBadge
      },
      {
        key: 'is_stattrak',
        component: VxCellBadges,
        sortable: true,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isStattrak) => boolStatusFormat(isStattrak)
      },
      {
        key: 'is_souvenir',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isSouvenir) => boolStatusFormat(isSouvenir)
      },
      {
        key: 'main_price',
        label: 'Our Price',
        component: VxCellMoney
      },
      { key: 'available' }
    ]

    const filters = [
      { key: 'id' },
      {
        key: 'market_hash_name',
        type: filterTypes.text
      },
      {
        key: 'is_stattrak',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_souvenir',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'weapon',
        label: 'Category',
        type: filterTypes.serverSelect,
        resource: categories.getNames,
        resourceMapper: ({ names }) => names.sort()
      },
      {
        key: 'finish',
        label: 'Variation',
        type: filterTypes.serverSelect,
        resource: categories.getVariations,
        resourceMapper: ({ variations }) => variations.sort()
      },
      {
        key: 'exterior',
        type: filterTypes.select,
        options: computed(() => Object.values(exteriors.value)),
        optionsLabel: 'full',
        reduce: ({ short, full }) => short || full
      },
      {
        key: 'rarity',
        type: filterTypes.select,
        options: computed(() => Object.values(rarities.value)),
        optionsLabel: 'full',
        reduce: ({ short }) => short
      }
    ]

    return {
      columns,
      filters,
      resource,
      SkinItemDetails,
      prefetch
    }
  }
}
</script>
