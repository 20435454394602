<template>
  <div>
    <div class="mb-1 font-medium-2">
      <span class="font-weight-bold">
        Market hash name:
      </span>
      <span class="">{{ marketHashName }}</span>
    </div>
    <vx-table-simple
      :columns="columns"
      :items="items"
    />
  </div>
</template>

<script>
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import { VxCellDate, VxCellMoney } from '@/components/table'

export default {
  name: 'SkinItemDetails',
  components: {
    VxTableSimple,
    VxCellMoney,
    VxCellDate
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { item: { market_skins, market_hash_name } } }) {
    const columns = [
      {
        key: 'market.title',
        label: 'Title'
      },
      {
        key: 'main_price',
        label: 'Price',
        component: VxCellMoney
      },
      {
        key: 'available',
        label: 'Count'
      },
      {
        key: 'updated_at',
        component: VxCellDate
      }
    ]

    return {
      items: market_skins,
      columns,
      marketHashName: market_hash_name
    }
  }
}
</script>
